<template>
  <CRow class="mt-5 row justify-content-md-center">
    <CCol md="3">
      <div class="ml-2">
        <h4><strong>好店緣2.0 註冊者調查</strong></h4>
        <h6>
          感謝您撥出幾分鐘的時間填寫以下問卷，讓我們更了解您對於開店的需求。
        </h6>
      </div>
    </CCol>
    <CCol md="8">
      <CCard>
        <CCardBody class="p-4">
          <form @submit.prevent="sendForm" class="surveyV2R">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label
                    ><strong class="text-danger"
                      >*<span class="h5 text-green">性別</span></strong
                    ></label
                  >
                  <br />
                  <div>
                    <input
                      type="radio"
                      id="sexMale"
                      value="男"
                      v-model="gender"
                      required
                    />
                    <label for="sexMale">男性</label>
                    <br />
                    <input
                      type="radio"
                      id="sexFemale"
                      value="女"
                      v-model="gender"
                      required
                    />
                    <label for="sexFemale">女性</label>
                    <br />
                    <input
                      type="radio"
                      id="sexOthers"
                      value="其他"
                      v-model="gender"
                      required
                    />
                    <label for="sexOthers"> 其他</label>
                  </div>
                </div>

                <div class="form-group mt-5">
                  <label
                    ><strong class="text-danger"
                      >*<span class="h5 text-green">年齡</span></strong
                    ></label
                  >
                  <br />
                  <input
                    type="radio"
                    id="age25"
                    value="25歲以下"
                    v-model="age"
                    required
                  />
                  <label for="age25">25歲以下</label>
                  <br />
                  <input
                    type="radio"
                    id="age30"
                    value="25歲~30歲"
                    v-model="age"
                    required
                  />
                  <label for="age30">25歲~30歲</label>
                  <br />
                  <input
                    type="radio"
                    id="age40"
                    value="31歲~40歲"
                    v-model="age"
                    required
                  />
                  <label for="age40">31歲~40歲</label>
                  <br />
                  <input
                    type="radio"
                    id="age50"
                    value="41歲~50歲"
                    v-model="age"
                    required
                  />
                  <label for="age50">41歲~50歲</label>
                  <br />
                  <input
                    type="radio"
                    id="age60"
                    value="51歲~60歲"
                    v-model="age"
                    required
                  />
                  <label for="age60">51歲~60歲</label>
                  <br />
                  <input
                    type="radio"
                    id="age61"
                    value="61歲以上"
                    v-model="age"
                    required
                  />
                  <label for="age61">61歲以上</label>
                </div>
                <div class="form-group mt-5">
                  <label
                    ><strong class="text-danger"
                      >*<span class="h5 text-green"
                        >請問您是自備店面還是總部代尋</span
                      ></strong
                    ></label
                  >
                  <br />
                  <input
                    type="radio"
                    id="storeMy"
                    value="自備店面"
                    v-model="store"
                    required
                  />
                  <label for="storeMy">自備店面</label>
                  <br />
                  <input
                    type="radio"
                    id="storeHeadquarters"
                    value="總部代尋"
                    v-model="store"
                    required
                  />
                  <label for="storeHeadquarters">總部代尋</label>
                  <br />
                  <input
                    type="radio"
                    id="storeNotDecide"
                    value="尚未決定"
                    v-model="store"
                    required
                  />
                  <label for="storeNotDecide">尚未決定</label>
                  <br />
                  <input
                    type="radio"
                    id="storeNotOthers"
                    value="__other_option__"
                    v-model="store"
                    required
                  />
                  <label for="storeNotOthers">其他</label>
                  <CInput placeholder="其他" v-model="storeOthers"> </CInput>
                </div>
                <div class="form-group mt-5">
                  <label
                    ><strong class="text-danger"
                      >*<span class="h5 text-green"
                        >您想加盟/創業的類別?</span
                      ></strong
                    ></label
                  >
                  <br /><label><input type="checkbox" v-model="storeType1" />手搖飲料</label>
                  <br /><label><input type="checkbox" v-model="storeType2" />咖啡店</label>
                  <br /><label><input type="checkbox" v-model="storeType3" />早餐店</label>
                  <br /><label><input type="checkbox" v-model="storeType4" />便當店</label>
                  <br /><label><input type="checkbox" v-model="storeType5" />養生/健康餐飲店</label>
                  <br /><label><input type="checkbox" v-model="storeType6" />炸雞/雞排店</label>
                  <br /><label><input type="checkbox" v-model="storeType7" />滷味店</label>
                  <br /><label><input type="checkbox" v-model="storeType8" />其他平價/街邊小吃(例：蔥油餅、東山鴨頭、胡椒餅等)</label>
                  <br /><label><input type="checkbox" v-model="storeType9" />餐廳(例：牛排館、漢堡店等）</label>
                  <br /><label><input type="checkbox" v-model="storeType10" />其他</label>
                  <CInput placeholder="其他" v-model="storeTypeOthers">
                  </CInput>
                </div>
                <div class="form-group mt-5">
                  <label
                    ><strong class="text-danger"
                      >*<span class="h5 text-green"
                        >您預期開店地區?</span
                      ></strong
                    ></label
                  >
                  <br /><label><input type="checkbox" v-model="storeLocation1" />基隆市</label>
                  <br /><label><input type="checkbox" v-model="storeLocation2" />台北市</label>
                  <br /><label><input type="checkbox" v-model="storeLocation3" />新北市</label>
                  <br /><label><input type="checkbox" v-model="storeLocation4" />桃園市</label>
                  <br /><label><input type="checkbox" v-model="storeLocation5" />宜蘭縣</label>
                  <br /><label><input type="checkbox" v-model="storeLocation6" />新竹/苗栗</label>
                  <br /><label><input type="checkbox" v-model="storeLocation7" />台中/彰化</label>
                  <br /><label><input type="checkbox" v-model="storeLocation8" />南投/雲林/嘉義</label>
                  <br /><label><input type="checkbox" v-model="storeLocation9" />台南/高雄/屏東</label>
                  <br /><label><input type="checkbox" v-model="storeLocation10" />台東/花蓮</label> 
                  <br /><label><input type="checkbox" v-model="storeLocation11" />外島(澎湖、金門、馬祖等)</label>
                </div>
                <div class="form-group mt-5">
                  <label
                    ><strong class="text-danger"
                      >*<span class="h5 text-green"
                        >您為什麼願意註冊/使用此平台?</span
                      ></strong
                    ></label
                  >
                  <br />
                  <input
                    type="radio"
                    id="reason1"
                    value="想掌握更多店面周遭數據，以利後續做決策"
                    v-model="reason"
                    required
                  />
                  <label for="reason1"
                    >想掌握更多店面周遭數據，以利後續做決策</label
                  >
                  <br />
                  <input
                    type="radio"
                    id="reason2"
                    value="想透過第三方平台再次確認目標店面是否適合開店，增加開店信心度"
                    v-model="reason"
                    required
                  />
                  <label for="reason2"
                    >想透過第三方平台再次確認目標店面是否適合開店，增加開店信心度</label
                  >
                  <br />
                  <input
                    type="radio"
                    id="reason3"
                    value="手上有兩間(含)以上的目標店面，還無法決定哪一間最適合開店，想透過數據做最後決策"
                    v-model="reason"
                    required
                  />
                  <label for="reason3"
                    >手上有兩間(含)以上的目標店面，還無法決定哪一間最適合開店，想透過數據做最後決策</label
                  >
                  <br />
                  <input
                    type="radio"
                    id="reason4"
                    value="還沒有任何目標開店地點，但想先透過數據快速篩選出最適合的地點，增加開店效率"
                    v-model="reason"
                    required
                  />
                  <label for="reason4"
                    >還沒有任何目標開店地點，但想先透過數據快速篩選出最適合的地點，增加開店效率</label
                  >
                  <br />
                  <input
                    type="radio"
                    id="reasonOthers"
                    value="__other_option__"
                    v-model="reason"
                    required
                  />
                  <label for="reasonOthers">其他</label>
                  <CInput placeholder="其他" v-model="reasonOthers"> </CInput>
                </div>
                <div class="form-group mt-5">
                  <label
                    ><strong
                      ><span class="h5 text-green"
                        >若您有其他建議或疑問，都歡迎向我們提出！</span
                      ></strong
                    ></label
                  >
                  <CTextarea
                    v-model="memo"
                    placeholder="您的回答"
                    rows="5"
                  ></CTextarea>
                </div>
                <div class="text-center mt-4">
                  <button type="submit" class="btn btn-block btn-success">
                    <strong class="text-white">送出</strong>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </CCardBody>
      </CCard>
    </CCol>
    <CModal title="" color="warning" :show.sync="isFormMissing">
      <div class="text-center">
        <p>請填寫：{{ missingFields.join(",") }}，謝謝</p>
      </div>
      <template #header>
        <div></div>
      </template>
      <template #footer>
        <CButton @click="isFormMissing = false" color="warning">
          <span class="text-white">關閉</span>
        </CButton>
      </template>
    </CModal>

    <ABTest ref="refABTest" :shouldMount="false"></ABTest>
  </CRow>
</template>

<script>
import axios from "axios";
import store from "../../util/store";
import appSettings from "../../util/settings";
import ABTest from "../abTest/ABTest";
import $ from "jquery";

export default {
  components: { ABTest },
  data() {
    return {
      gender: undefined,
      age: undefined,
      store: undefined,
      storeOthers: undefined,
      storeType1: undefined,
      storeType2: undefined,
      storeType3: undefined,
      storeType4: undefined,
      storeType5: undefined,
      storeType6: undefined,
      storeType7: undefined,
      storeType8: undefined,
      storeType9: undefined,
      storeType10: undefined,
      storeTypeOthers: undefined,
      storeLocation1: undefined,
      storeLocation2: undefined,
      storeLocation3: undefined,
      storeLocation4: undefined,
      storeLocation5: undefined,
      storeLocation6: undefined,
      storeLocation7: undefined,
      storeLocation8: undefined,
      storeLocation9: undefined,
      storeLocation10: undefined,
      storeLocation11: undefined,
      reason: undefined,
      reasonOthers: undefined,
      memo: undefined,
      username: undefined,
      isFormMissing: false,
      missingFields: [],
    };
  },
  mounted() {
    axios
      .post(appSettings.MyInfo, {
        userToken: store.state.token,
      })
      .then(this.setMyIfo)
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    setMyIfo(response) {
      var data = response.data;
      if (!data.IsLogin) {
        store.commit("clearUser");
        this.$router.replace({ name: "Login" });
      } else {
        var result = data.Result;
        this.username = result.UserId;
      }
    },
    sendForm() {
      let url =
        "https://docs.google.com/forms/u/0/d/e/1FAIpQLSdEND4nkbQNLcWpwa7PGxORvLrRXcjRdgG_-zPG6ykRXMqI3A/formResponse?";

      url += "entry.567548336=" + this.username;
      url += "&entry.1643872427=" + this.memo;

      this.missingFields = [];
      if (this.gender === undefined) {
        this.missingFields.push("性別");
      } else {
        url += "&entry.864828090=" + this.gender;
      }

      if (this.age === undefined) {
        this.missingFields.push("年齡");
      } else {
        url += "&entry.865012398=" + this.age;
      }

      if (this.store === undefined) {
        this.missingFields.push("自備店面還是總部代尋");
      } else {
        url += "&entry.900014408=" + this.store;
      }

      if (this.storeOthers !== undefined && this.storeOthers !== "") {
        url += "&entry.900014408.other_option_response=" + this.storeOthers;
      }

      let storeType = false;
      if (this.storeType1 === true) {
        url += "&entry.2142151916=手搖飲料";
        storeType = true;
      }
      if (this.storeType2 === true) {
        url += "&entry.2142151916=咖啡店";
        storeType = true;
      }
      if (this.storeType3 === true) {
        url += "&entry.2142151916=早餐店";
        storeType = true;
      }
      if (this.storeType4 === true) {
        url += "&entry.2142151916=便當店";
        storeType = true;
      }
      if (this.storeType5 === true) {
        url += "&entry.2142151916=養生/健康餐飲店";
        storeType = true;
      }
      if (this.storeType6 === true) {
        url += "&entry.2142151916=炸雞/雞排店";
        storeType = true;
      }
      if (this.storeType7 === true) {
        url += "&entry.2142151916=滷味店";
        storeType = true;
      }
      if (this.storeType8 === true) {
        url +=
          "&entry.2142151916=其他平價/街邊小吃(例：蔥油餅、東山鴨頭、胡椒餅等)";
        storeType = true;
      }
      if (this.storeType9 === true) {
        url += "&entry.2142151916=餐廳(例：牛排館、漢堡店等）";
        storeType = true;
      }
      if (this.storeType10 === true) {
        url += "&entry.2142151916=__other_option__";
        storeType = true;
      }

      if (storeType === false) {
        this.missingFields.push("加盟/創業的類別");
      }

      if (this.storeTypeOthers !== undefined && this.storeTypeOthers !== "") {
        url +=
          "&entry.2142151916.other_option_response=" + this.storeTypeOthers;
      }

      let storeLocation = false;
      if (this.storeLocation1 === true) {
        url += "&entry.163339316=基隆市";
        storeLocation = true;
      }
      if (this.storeLocation2 === true) {
        url += "&entry.163339316=台北市";
        storeLocation = true;
      }
      if (this.storeLocation3 === true) {
        url += "&entry.163339316=新北市";
        storeLocation = true;
      }
      if (this.storeLocation4 === true) {
        url += "&entry.163339316=桃園市";
        storeLocation = true;
      }
      if (this.storeLocation5 === true) {
        url += "&entry.163339316=宜蘭縣";
        storeLocation = true;
      }
      if (this.storeLocation6 === true) {
        url += "&entry.163339316=新竹/苗栗";
        storeLocation = true;
      }
      if (this.storeLocation7 === true) {
        url += "&entry.163339316=台中/彰化";
        storeLocation = true;
      }
      if (this.storeLocation8 === true) {
        url += "&entry.163339316=南投/雲林/嘉義";
        storeLocation = true;
      }
      if (this.storeLocation9 === true) {
        url += "&entry.163339316=台南/高雄/屏東";
        storeLocation = true;
      }
      if (this.storeLocation10 === true) {
        url += "&entry.163339316=台東/花蓮";
        storeLocation = true;
      }
      if (this.storeLocation11 === true) {
        url += "&entry.163339316=外島(澎湖、金門、馬祖等)";
        storeLocation = true;
      }

      if (storeLocation === false) {
        this.missingFields.push("預期開店地區");
      }

      if (this.reason === undefined) {
        this.missingFields.push("為什麼願意註冊");
      } else {
        url += "&entry.1656253242=" + this.reason;
      }

      if (this.reasonOthers !== undefined && this.reasonOthers !== "") {
        url += "&entry.1656253242.other_option_response=" + this.reasonOthers;
      }

      var settings = {
        url: url,
        method: "POST",
        timeout: 0,
        dataType: "jsonp",
        jsonp: "callback",
        jsonpCallback: "jsonpCallback",
      };

      if (this.missingFields.length > 0) {
        this.isFormMissing = true;
      } else {
        //console.log(url);
        let self = this;
        $.ajax(settings)
          .done(function (response) {
            //console.log(response);
            self.afterSumbit();
          })
          .fail(function (jqXHR, textStatus, errorThrown) {
            console.log(errorThrown);
            self.afterSumbit();
          });
      }
    },
    afterSumbit() {
      let self = this;
      self.$refs["refABTest"].closeCallbackFunction = function () {
        self.$router.push({
          name: "Home",
        });
      };
      self.$refs["refABTest"].doFreeTrail();
    },
  },
};
</script>